import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar.js'
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Footer from '../../components/footer/Footer.js'
import { handleAddressClick, handlePhoneClick, handleEmailClick } from '../../components/footer/Footer.js';
import officeInfo from '../../api/addressInfo.js';
import { BsClockFill } from "react-icons/bs";
import { FaMapMarkedAlt,FaPhone  } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import Masonry from '@mui/lab/Masonry';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';

// images
import officeImg1 from '../../images/clinic/waiting_room.jpg'
import officeImg2 from '../../images/clinic/hallway.jpg'
import officeImg3 from '../../images/clinic/room2.jpg'
import officeImg4 from '../../images/clinic/front_desk.jpg'
import officeImg5 from '../../images/clinic/stock_room.jpg'
import officeImg6 from '../../images/clinic/kid_room.jpg'
const MapIframe = ({mapLink})  => {
    return (
        <div>
            <iframe
                title="Office Location"
                src={mapLink}
                allowFullScreen
                >

            </iframe>
        </div>
    )
}

const LocationPage = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Location | Up Dental of Manvel TX</title>
                <meta name="description" content="Location | Up Dental of Manvel TX" />
                <meta name="keywords" content="dental, dentist, Manvel, treatment, patients, office, appointment" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-2'} />
            {/* <PageTitle pageTitle={'Our Location'} pagesub={''} className={'wpo-page-title'} /> */}
            <LocationSection class={"section-padding"}/>
            {/* <Footer footerClass={'wpo-site-footer-s2'} /> */}
            <Footer  />
            <Scrollbar />
        </Fragment>
    )
};

export const LocationSection = (props) => {
    const mapLink = "https://www.google.com/maps/embed/v1/place?q=7218+Masters,+Manvel,+TX+77578&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
    const itemData = [ officeImg1, officeImg2, officeImg3, officeImg4, officeImg5, officeImg6];
   
    return (
        <section className={`wpo-location-section ${props.class}`} >
            <div className="container">
                <div className="row align-items-center" >
                    <div className="col-lg-6 col-md-12 col-12 left-side" >
                        <div className="wpo-section-title-left" >
                            {/* <span> Visit Us </span> */}
                            <h2>Visit Us</h2>
                            {/* <h1>Visit Us</h1> */}
                            <p>
                                We're conveniently located right across E.C. Mason Elementary School on Masters road thoroughfare.
                            </p>
               
                        </div>

                        <div className="row" id="office-wrapper">
                            <div onClick={handleAddressClick} className='office-location-container'>
                                <div className="react-icon-container"><FaMapMarkedAlt /></div>
                                <div className="info-container">
                                    <p>{officeInfo.address}</p>
                                </div>
                            </div>
                            <div onClick={handlePhoneClick} className='office-phone-container'>
                                <div className="react-icon-container"><FaPhone /></div>
                                
                                <div className="info-container">
                                    <p>{officeInfo.phone}</p>
                                </div>
                            </div>
                           
                            <div className='office-hours-container'>
                                <div className="react-icon-container"><BsClockFill /></div>
                                <div className="office-hours row">
                                    {officeInfo.schedule.map((hour, index) => (
                                        <div key={index} className="office-hour">
                                            <div className="day">{hour.day}</div>
                                            <div className="time">{hour.hours}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div onClick={handleEmailClick} className='office-email-container'>
                                <div><MdMarkEmailUnread /></div>
                                
                                <div className="info-container">
                                    <p>{officeInfo.email}</p>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    {/* image on the right mansory style*/}
                    <div className="col-lg-6 col-md-12 col-12">
                        {/* <div className="about-image"></div> */}
                        <div className="office-image-container">
                            {/* <Box sx={{ width: 500, minHeight: 829 }}> */}
                            <Box sx={{m: 5}}>
                                <Masonry 
                                    columns={2} 
                                    spacing={1}
                                    // defaultHeight={1450}
                                >
                                    {itemData.map((item, index) => (
                                        <div key={index}>
                                            <img
                                            srcSet={`${item}?w=162&auto=format&dpr=2 2x`}
                                            src={`${item}?w=162&auto=format`}
                                            alt="dental office rooms"
                                            loading="lazy"
                                            style={{
                                                borderBottomLeftRadius: 10,
                                                borderBottomRightRadius: 10,
                                                borderTopLeftRadius:10,
                                                borderTopRightRadius:10,
                                                display: 'block',
                                                width: '100%',                                             
                                            }}
                                            />
                                        </div>
                                    ))}
                                </Masonry>
                            </Box>
                        </div>
                    </div>

                </div>

                <div id="map-container">
                    <div>
                        <MapIframe mapLink={mapLink} />
                    </div>         
                </div>
            </div>
        </section>
    )
}


// const LocationSection_gridstyle = (props) => {
//     const mapLink = "https://www.google.com/maps/embed/v1/place?q=7218+Masters,+Manvel,+TX+77578&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
//     const OneImage = ({imgSrc}) => {
//         return (
//             <div>
//                 <img src={imgSrc} alt="">
//                 </img>
//             </div>
//         )
//     }
//     return (
//         <section className={`wpo-location-section section-padding  ${props.class}`} >
//             <div className="container">
//                 <div className="row align-items-center">
//                     <div className="col-lg-6 col-md-12 col-12 left-side" >
//                         <div className="wpo-about-text" >
//                             {/* <span> Visit Us </span> */}
//                             <h2>Visit Us</h2>
//                             <p>We’re conveniently located in the Denver Tech Center area. Find us on the 4th floor of the Triad Office Park building. Plenty of free parking available.</p>
               
//                         </div>

//                         <div className="row" id="office-wrapper">
//                             <div className='office-location-container'>
//                                 <div><FaMapMarkedAlt /></div>
                                
//                                 <div><p>{officeInfo.address}</p></div>
//                             </div>
//                             <div className='office-phone-container'>
//                                 <div><FaPhone /></div>
                                
//                                 <div><p>{officeInfo.phone}</p></div>
//                             </div>
                           
//                             <div className='office-hours-container'>
//                                 <div><BsClockFill /></div>
//                                 <div className="office-hours row">
//                                     {officeInfo.schedule.map((hour, index) => (
//                                         <div key={index} className="office-hour">
//                                             <div className="day">{hour.day}</div>
//                                             <div className="time">{hour.hours}</div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                             <div className='office-email-container'>
//                                 <div><MdMarkEmailUnread /></div>
                                
//                                 <div><p>{officeInfo.email}</p></div>
//                             </div>
//                         </div>
                      
//                     </div>
//                     {/* image on the right mansory style*/}
//                     <div className="col-lg-6 col-md-12 col-12">
//                         <div className="office-image-container">
//                         <Box sx={{ flexGrow: 1 }}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={8}>
//                                     <OneImage imgSrc={officeImg1}/>
//                                 </Grid>
//                                 <Grid item xs={4}>
//                                     <OneImage imgSrc={officeImg3}/>
//                                 </Grid>
//                                 <Grid item xs={4}>
//                                     <OneImage imgSrc={officeImg4}/>
//                                 </Grid>
//                                 <Grid item xs={8}>
//                                 <OneImage imgSrc={officeImg1}/>
//                                 </Grid>
//                             </Grid>
//                         </Box>
//                         </div>
//                     </div>

//                 </div>

//                 <div id="map-container">
//                     <div>
//                         <MapIframe mapLink={mapLink} />
//                     </div>         
//                 </div>
//             </div>
//         </section>
//     )
// }
export default LocationPage;
