import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
// import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import FaqSection from '../../components/Faq/FaqSection';
// import OfficeHero from '../../components/hero2/OfficeHero';
import AboutDoctorHero from '../../components/hero/AboutDoctorHero';
// import SmileGalleryHero from '../../components/hero/SmileGalleryHero';
// import LocationHero from '../../components/hero/LocationHero';

import TestimonialStyle3 from '../../components/Testimonial/TestimonialStyle3';
import { InsuranceSection } from '../InsurancePage/InsurancePage';
import DentalServicesSectionStyle2 from '../../components/ServiceSection/DentalServicesSectionStyle2';
import FeatureStyle2 from '../../components/Features/FeatureStyle2';
import Footer from '../../components/footer/Footer';
import AppoinmentSection from '../../components/AppoinmentSec/AppoinmentSection';
// import { AllTeamMembersSlider } from '../../components/TeamSection/AllTeamMembers';
import { LocationSection } from '../LocationPage/LocationPage';
import OfficeHeroStyle2 from '../../components/hero2/OfficeHeroStyle2';
import Welcome from '../../components/welcome/Welcome';
import { Helmet } from 'react-helmet';
const HomePage = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Home | Comprehensive dental office Manvel TX</title>
                <meta name="description" content="Up Dental Of Manvel | Comprehensive dental office in Manvel TX"  />
                <meta name="keywords" content="dental, dentist, Manvel, treatment, patients, office, appointment" />
            </Helmet>
            <Navbar />
            <Welcome />
            <LocationSection class={"section-padding"}/>
            {/* <LocationHero /> */}
            <FeatureStyle2 />
            <DentalServicesSectionStyle2 />
            <OfficeHeroStyle2 class={"section-bg"}/>
            {/* <OfficeHero /> */}
            
            <AboutDoctorHero />
            {/* SHOW WHEN YOU HAVE INFO OF WHO IS WORKING */}
            {/* <AllTeamMembersSlider class={"section-padding section-bg-2 section-divider-style1 "}/> */}
            
            {/* <SmileGalleryHero /> */}

            <InsuranceSection />
            <TestimonialStyle3 />
            <FaqSection questionNum={5}/>
            {/* <AppoinmentSec /> */}
            {/* <LocationSection class={"section-bg-2 p-20"}/> */}
            <AppoinmentSection />
          
            <Footer />
           
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;